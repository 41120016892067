/**
 * This is the main entrypoint for you client side resources.
 * Import any modules that should be available on the page.
 */
import pulse from '@finn-no/pulse-sdk';
import '@warp-ds/icons/elements/sofa-42';
import '@warp-ds/icons/elements/link-external-16';
import '@warp-ds/icons/elements/chevron-right-16';
import '@warp-ds/icons/elements/job-42';
import '@warp-ds/icons/elements/minivan-42';
import '@warp-ds/icons/elements/real-estate-42';
import '@warp-ds/icons/elements/airplane-42';
import '@warp-ds/icons/elements/sailboat-42';
import '@warp-ds/icons/elements/motorcycle-42';
import '@warp-ds/icons/elements/cabin-42';
import '@warp-ds/icons/elements/phone-badge-check-42';
import '@warp-ds/icons/elements/honk-light-42';
import '@warp-ds/icons/elements/nettbil-light-42';
import '@warp-ds/icons/elements/remppatori-light-42';
import '@warp-ds/icons/elements/chair-42';
import '@warp-ds/icons/elements/shirt-42';
import '@warp-ds/icons/elements/stroller-42';
import '@warp-ds/icons/elements/ice-skater-42';
import '@warp-ds/icons/elements/paw-42';
import '@warp-ds/icons/elements/guitar-bat-42';
import '@warp-ds/icons/elements/oikotie-light-42';
import '@warp-ds/icons/elements/car-front-42';
import '@warp-ds/icons/elements/smart-phone-42';
import '@warp-ds/icons/elements/tools-42';
import '@warp-ds/icons/elements/car-part-42';
import '@warp-ds/icons/elements/vase-42';
import '@warp-ds/icons/elements/autovex-light-42';
import '@warp-ds/icons/elements/geometric-shapes-42';

import './components/market-icons.js';
import './components/categories-section.js';

// Pulse tracking.
pulse.trackPageView({
    object: {
        type: 'Frontpage',
    },
    vertical: {
        name: 'common_pages',
    },
});
